import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:出库信息批量打印
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12358
 */
export default function deliverOrderBatch(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/batch/print', data);
}
/**
 * @description:批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15013
 */
export function batchFieldList() {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', {});
}
/**
 * @description:加密出库凭证，用于提供给客户不需要登录查看出库凭证
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18389
 */
export function encryptBatchList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/encrypt/batch/print', data);
}
