// 送货单类型
export const DeliveryType = {
  BOOKING: 'booking', // 预打送货单
  DELIVERY: 'delivery', // 送货单
};

// 预约数量
const BOOKING_NUM = 'bookingNum';
// 默认预约数量
const BOOKING_DEFAULT_UNIT_QUANTITY = 'bookingDefaultUnitQuantity';
// 默认预约数量总计
const TOTAL_BOOKING_DEFAULT_UNIT_QUANTITY = 'totalBookingDefaultUnitQuantity';

// 拣货数量
const DELIVER_NUM = 'deliverNum';
// 默认拣货数量
const DELIVER_DEFAULT_UNIT_QUANTITY = 'bookingDefaultUnitQuantity';
// 默认拣货数量总计
const TOTAL_DELIVER_DEFAULT_UNIT_QUANTITY = 'totalBookingDefaultUnitQuantity';

export const print = {
  booking: {
    conversionNum: BOOKING_NUM,
    defaultNum: BOOKING_DEFAULT_UNIT_QUANTITY,
    defaultTotal: TOTAL_BOOKING_DEFAULT_UNIT_QUANTITY,
  },
  delivery: {
    conversionNum: DELIVER_NUM,
    defaultNum: DELIVER_DEFAULT_UNIT_QUANTITY,
    defaultTotal: TOTAL_DELIVER_DEFAULT_UNIT_QUANTITY,
  },
};
