export default {
  type: 'DELIVERY_PICK',
  sheetName: '送货单',
  maxRowsNumber: 16,
  title: {
    oneiline: false, // 是否一行显示
    sheetName: '货品清单(四)', // 打印单名称
    company: '', // 公司名称
  },
};
