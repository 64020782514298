<template>
  <div v-loading="loading.featchDate" class="print-page">
    <div class="print-btn">
      <PrintButton
        v-model="temperatureLayerCode"
        :type="defaultScreen?TEMPERATURE_LAYER_BUTTON.type:PRINT_BUTTON.type"
        :temperature-list="TEMPERAYURE_LIST"
        multiple="true"
        @change="temperatureLayerChange"
      />
      <div v-if="defaultScreen" class="print-btn">
        <div class="margin-24">
          选择批属性：
          <el-select
            v-model="selectBatchAttributes"
            multiple
            :multiple-limit="2"
            placeholder="请选择"
          >
            <el-option
              v-for="item in batchAttributes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <p class="margin-24">
          说明：最多可选择2个批属性。
        </p>
      </div>
      <div v-if="isMerge" class="margin-24">
        <span>合并门店</span>
        <el-switch v-model="mergeStores" />
      </div>
    </div>
    <div v-if="!mergeStores">
      <div v-for="(printList, printNumber) in prints" :key="printNumber">
        <component
          :is="printList.component"
          v-if="printList.component === config.DEFAULT"
          :batch-attributes="dynamicList"
          :print-list="printList"
          :temperature-code="temperatureLayer"
        />
        <component
          :is="printList.component"
          v-else
          :print-list="printList"
          :merge-stores="mergeStores"
        />
      </div>
    </div>
    <div v-else>
      <div v-for="(printList, printNumber) in mergeTable" :key="printNumber">
        <component
          :is="printList.component"
          v-if="printList.component === config.DEFAULT"
          :batch-attributes="dynamicList"
          :print-list="printList"
          :temperature-code="temperatureLayer"
        />
        <component
          :is="printList.component"
          v-else
          :print-list="printList"
          :merge-stores="mergeStores"
        />
      </div>
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';

import moment from 'moment';
import { YMD } from '@/constant/timeFormat';
import { TEMPERATURE_LAYER } from '@/constant/temperatureLayer';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import { TEMPERATURE_LAYER_BUTTON, PRINT_BUTTON } from '../components/PrintButton/config';

import Normal from './normal/index.vue';
import Customized1 from './customized1/index.vue';
import Customized2 from './customized2/index.vue';
import Customized3 from './customized3/index.vue';
import Customized4 from './customized4/index.vue';
import Customized5 from './customized5/index.vue';

import config from './config';
import fileds from './fileds';
import deliverOrderBatch, { batchFieldList, encryptBatchList } from './api';
import { HanBaoWangSortRule, TEMPERATURES } from './customized5/constant';

const CLASSIFY_PREFIX = '按温度分组合计：';
const CLASSIFY_HEADERS = [
  {
    label: '行号', prop: 'index', colspan: 6, class: 'no-border', width: '5%',
  },
  {
    label: '发货量', prop: 'deliverNum', class: 'no-border', width: '8%',
  },
  { label: '清点栏', class: 'no-border' },
  { label: '生产日期', width: '8%', class: 'no-border' },
  { label: '送货差异' },
];
const unfreezeCode = '-1';
export default {
  name: 'DeliveryListNormal',
  components: {
    PrintButton,
    Normal,
    Customized1,
    Customized2,
    Customized3,
    Customized4,
    Customized5,
  },
  mixins: [loadingMixin],
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      PRINT_BUTTON,
      config,
      fileds,
      TEMPERATURE_LAYER,
      deliverOrderIds: [], // 出库单ids
      batchAttributes: [{ value: 'productDate', label: '生产日期', type: 'date' }], // 批属性
      selectBatchAttributes: ['productDate'], // 默认选中生产日期
      templateCodeArray: [],
      prints: [], // 批量打印数据
      mergeTable: [],
      mergeStores: false, // 是否合并门店
      temperatureLayerCode: [], // 温层编码
      temperatureLayer: '',
      loading: {
        featchDate: false,
      },
      encrypt: false,
    };
  },
  computed: {
    dynamicList() {
      return this.selectBatchAttributes.map((item) => this.batchAttributes.find(
        (attr) => attr.value === item,
      ));
    },
    defaultScreen() {
      return this.prints.some((item) => item.component === config.DEFAULT);
    },
    isMerge() {
      return this.prints.every((item) => item.component === config.PRINT_CFG_YUNXIAN_BURGERKING);
    },
    TEMPERAYURE_LIST() {
      const list = this.TEMPERATURE_LAYER.map(({ code, name, layer }) => ({
        temperatureLayerCode: code,
        temperatureLayerName: name,
        temperatureLayer: layer,
      }));

      // 应产品要求，打印单筛选去除解冻选项
      const temperatureLayerList = list
        .filter((item) => item.temperatureLayerCode !== unfreezeCode);
      temperatureLayerList.unshift({ temperatureLayerCode: null, temperatureLayerName: '全部' });
      return temperatureLayerList;
    },
  },
  created() {
    const { ids, encrypt } = this.$route.query;
    this.encrypt = encrypt;
    if (Array.isArray(ids)) {
      this.deliverOrderIds = ids;
    } else {
      this.deliverOrderIds = [ids];
    }
    this.init();
  },
  methods: {
    getLabel(val, arr) {
      const findAttr = arr && arr.find((item) => item.value === val);
      return findAttr ? findAttr.label : '';
    },
    async init() {
      this.getBatchAttributes();
      this.featchDate();
    },
    // 获取批次字段
    async getBatchAttributes() {
      const resolve = await batchFieldList();
      resolve.forEach((item) => {
        if (!this.batchAttributes.some((batch) => batch.value === item.fieldCode)) {
          this.batchAttributes.push({
            value: item.fieldCode, label: item.fieldName, type: item.component,
          });
        }
      });
    },
    temperatureLayerChange(e) {
      e.forEach((item) => {
        if (item === null) {
          this.temperatureLayerCode = [null, '1', '2', '3', '4'];
        }
      });
      const arr = [];
      this.TEMPERAYURE_LIST.forEach((item) => {
        this.temperatureLayerCode.forEach((i) => {
          if (item.temperatureLayerCode === i) {
            arr.push(item.temperatureLayer);
          }
        });
      });
      //   const result = this.TEMPERAYURE_LIST.find((item) => item.temperatureLayerCode
      // === this.temperatureLayerCode);
      this.temperatureLayer = arr.join(',');
      this.featchDate();
    },
    async featchDate() {
      this.prints = [];
      let prints = [];
      if (this.encrypt === 'true') {
        prints = await encryptBatchList(
          {
            deliverOrderIds: this.deliverOrderIds,
            temperatureLayerCode: this.temperatureLayerCode.join(','),
          },
        );
      } else {
        // temperatureLayerCode参数只影响总质量及总体积，并未做筛选
        prints = await deliverOrderBatch(
          {
            deliverOrderIds: this.deliverOrderIds,
            temperatureLayerCode: this.temperatureLayerCode.join(','),
          },
        );
      }

      this.prints = prints.map((item) => {
        const { goods = [], ...publicPrint } = item;
        return {
          publicPrint: {
            ...publicPrint,
            pickupType: this.getLabel(publicPrint.pickupType, fileds.PICKUP_TYPE),
            deliverType: this.getLabel(publicPrint.deliverType, fileds.DELIVER_TYPE),
            bookingTime: this.formatTime(publicPrint.bookingTime),
            deliverTime: this.formatTime(publicPrint.deliverTime),
            createTime: this.formatTime(publicPrint.createTime),
            warehouseLinkMan: publicPrint.warehouseLinkMan + publicPrint.warehouseTelephone,
          },
          component: config[publicPrint.templateCode],
          tables: goods,
        };
      });
      this.mergeTable = this.mergeData(this.prints);
    },
    mergeData(data) {
      const noMergeData = [...data];
      const isMergeData = [];
      const mergeDataCode = {};
      noMergeData.forEach(({ publicPrint, tables, component }) => {
        // 通过门店code和门店名称共同判断是否是同一门店
        const newTable = this.mergeTableAfter(tables);
        const storeCodeName = publicPrint.thirdShopCode + publicPrint.stationName;
        const temperatures = this.getTemperature(tables);
        if (!mergeDataCode[storeCodeName]) {
          // 当没有这个门店时
          isMergeData.push(
            {
              publicPrint,
              component,
              mergeTable: [{
                deliverOrderNo: publicPrint.deliverOrderNo,
                remark: publicPrint.remark,
                bookingTime: publicPrint.bookingTime,
                customerOrderNo: publicPrint.customerOrderNo,
                goodsSaleTypeName: publicPrint.ext?.goodsSaleTypeName || '',
                temperatures,
                salesOrderNo: publicPrint.salesOrderNo,
                ext: publicPrint.ext,
                tableData: [...newTable],
              }],
              currentStores: storeCodeName,
            },
          );
          mergeDataCode[storeCodeName] = true;
        } else {
          // 当已经有这个门店时
          isMergeData.forEach(({ currentStores, mergeTable }) => {
            if (currentStores === storeCodeName) {
              mergeTable.push(
                {
                  deliverOrderNo: publicPrint.deliverOrderNo,
                  remark: publicPrint.remark,
                  bookingTime: publicPrint.bookingTime,
                  customerOrderNo: publicPrint.customerOrderNo,
                  goodsSaleTypeName: publicPrint.ext?.goodsSaleTypeName || '',
                  ext: publicPrint.ext,
                  temperatures,
                  salesOrderNo: publicPrint.salesOrderNo,
                  tableData: [...newTable],
                },
              );
            }
          });
        }
      });
      const newIsMergeData = this.mergeDataSort(isMergeData);
      return newIsMergeData.map((item) => ({
        ...item,
        tables: item.mergeTable,
      }));
    },
    getTemperature(list) {
      const temperatures = new Set();
      list.forEach((item) => {
        if (item.goodsTypeCode) {
          temperatures.add(TEMPERATURES[item.goodsTypeCode]);
        }
      });
      return [...temperatures].join(' + ');
    },
    mergeTableAfter(table) {
      const list = [];
      let lineNumber = 1; // 行号
      let totalPickNum = 0;
      table.forEach((current, index) => {
        totalPickNum += current.deliverNum * 1000;
        list.push({
          ...current,
          productDate: this.formatTime(current.productDate),
          index: lineNumber,
        });
        const next = table[index + 1];
        if (table.length - 1 === index) {
          // 如果是最后一行，添加温层栏
          this.addClassifyLabel(list, current, totalPickNum);
          return;
        }
        if (current.goodsTypeCode === next.goodsTypeCode) {
          lineNumber += 1;
        } else {
          this.addClassifyLabel(list, current, totalPickNum);
          lineNumber = 1;
          totalPickNum = 0;
        }
      });
      return list;
    },
    addClassifyLabel(list, info, deliverNum) {
      const { goodsTypeCode } = info;
      const classifiedInfo = `${CLASSIFY_PREFIX}${TEMPERATURES[goodsTypeCode] || ''}`;
      list.push({
        ...info,
        index: classifiedInfo,
        headers: CLASSIFY_HEADERS,
        deliverNum: deliverNum / 1000,
      });
    },
    // 对合并后的数据进行排序
    mergeDataSort(isMergeData) {
      return isMergeData.map((item) => {
        const newItem = item;
        newItem.mergeTable = newItem.mergeTable
          .sort((item1, item2) => [...HanBaoWangSortRule].reverse()
            .indexOf(item2.goodsSaleTypeName)
            - [...HanBaoWangSortRule].reverse().indexOf(item1
              .goodsSaleTypeName));

        return newItem;
      });
    },
    formatTime(time) {
      return time && moment(time).format(YMD);
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  max-width: 1123px;
  margin: 0 auto;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

@media print {
  .print-btn {
    display: none;
  }
}
 </style>
