<template>
  <div class="print-list">
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      :class="{
        'print-contanier': tableNumber !== tables.length - 1,
        'print-padding': tableNumber !== 0
      }"
    >
      <print-header
        :order="publicPrint.deliverOrderNo"
        :title="config"
        :show-ext="false"
        :title-name="titleName"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }}
              <span :style="item.textStyle">{{ publicPrint[item.value] }}</span>
            </p>
          </div>
        </div>
      </div>
      <print-table
        class="table"
        :headers="fileds.TABLECOLUMN"
        :list="table"
      />
    </div>
    <div class="footer-sign">
      <div
        v-for="(row, index) in fileds.FOOTER_SIGN"
        :key="index"
        class="row border"
      >
        <div
          v-for="(item, i) in row"
          :key="i"
          class="col"
          :style="{'flex': item.width}"
        >
          <p v-if="item.label" :style="{textAlign: item.align}">
            {{ item.label }}
          </p>
          <p v-else>
            {{ publicPrint[item.value] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomerTitle } from '@/api/shipper';
import unitNumber from '@/utils/unitNumber';
import Moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import { TemperatureLayerType } from '@/constant/temperatureLayer';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';

import { PRINT_BUTTON } from '../../components/PrintButton/config';
import config from './config';
import fileds from './fileds';

export default {
  name: 'DeliveryListNormalNor',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PRINT_BUTTON,
      config,
      fileds,
      publicPrint: {},
      tables: [],
      titleName: '',
    };
  },

  created() {
    this.tables = this.formatTable();
  },
  mounted() {
    getCustomerTitle({ shipperId: this.publicPrint.shipperId }).then((res) => {
      this.titleName = res || '鲜生活冷链物流有限公司';
    });
  },
  methods: {
    formatTable() {
      const { tables: list = [], publicPrint = {} } = this.printList;
      const tables = [];
      this.publicPrint = {
        ...publicPrint,
        stationContact: `${publicPrint.stationContact}-${publicPrint.stationTelephone}`,
      };
      let tablePage = 0;
      let totalPrice = 0;
      let deliverNumTotal = 0;
      let bookingDefaultUnitQuantityTotal = 0;
      const sortList = this.sortTemperatureLayer(list);
      sortList.forEach((val, index) => {
        const { txt: bookingNumTxt } = unitNumber.calculate(
          { ...val, smallUnitNumber: val.bookingNum },
        );

        const deliverNum = this.calculateByDefaultUnit({ ...val, smallNumtotal: val.deliverNum });
        deliverNumTotal += deliverNum * 1000;
        bookingDefaultUnitQuantityTotal += val.bookingDefaultUnitQuantity * 1000;
        const unitPrice = val.ext?.originalExt?.price; // 单价
        const goodsPrice = (unitPrice || 0) * 100
        * ((deliverNum || 0) * 1000);
        totalPrice += goodsPrice;
        const item = {
          ...val,
          deliverNum,
          productDate: Moment.format(val.productDate, YMD),
          expireDate: Moment.format(Moment.add(val.productDate, { day: val.shelfLife }), YMD),
          temperatureLayerTxt: TemperatureLayerType[val.temperatureLayer],
          bookingNumTxt,
          index: index + 1,
          unitPrice,
          totalPrice: Math.round(goodsPrice / 1000) / 100 || 0,
        };
        if (index === config.maxRowsNumber * tablePage) {
          tablePage += 1;
          tables.push([]);
        }
        tables[tablePage - 1].push(item);
      });

      const row = {
        shipperGoodsCode: '总计',
        goodsName: '汇总重量/方量',
        productDate: `${this.publicPrint.totalBookingWeight}kg`,
        expireDate: `${this.publicPrint.totalBookingVolume}m³`,
        bookingDefaultUnitQuantity: bookingDefaultUnitQuantityTotal / 1000 || '',
        deliverNum: deliverNumTotal / 1000 || '', // 发货量
        totalPrice: totalPrice / 100000 || '',
      };

      tables[tablePage - 1].push(row);
      return tables;
    },
    // 使用默认单位计算总量
    // 业务：通常返回来的预约数量、发货量都是零单位数量，如果默认单位为整单位数量，应该转为整单位数量
    calculateByDefaultUnit({
      conversionRate,
      defaultUnit, smallUnit, smallNumtotal,
    }) {
      if (defaultUnit === smallUnit) {
        return smallNumtotal;
      }

      return Math.round((smallNumtotal * 1000) / conversionRate) / 1000;
    },
    // 温层排序
    sortTemperatureLayer(lists) {
      const newLists = [];
      this.fileds.temperatureLayerSorting.forEach((item) => {
        const findList = lists.filter((list) => list.temperatureLayer === item);
        if (findList.length > 0) {
          newLists.push(...this.sortShipperGoodsCode(findList));
        }
      });
      return newLists;
    },
    // 按照货主货品编码从小到大进行排序
    sortShipperGoodsCode(lists) {
      const sortList = [...lists];
      sortList.sort((a, b) => {
        const n1 = a.shipperGoodsCode;
        const n2 = b.shipperGoodsCode;
        const numA = Number(n1) || false;
        const numB = Number(n2) || false;
        return (numA !== numB && numA - numB) || (numA && numB ? n1 - n2 : n1.localeCompare(n2));
      });
      return sortList;
    },

  },
};
</script>
<style lang="scss" scoped>
.print-list {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }

    .increase-size {
      font-size: 20px;
    }
  }
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    height: 28px;
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  p {
    padding: 0 10px;
    font-size: 14px;
  }
}

@media print {
  // 送货单顶部边距需要增加。因为实际打印时需要再设置边距，然后头部有部分信息展示不全。
  $paddingTop: 10px;

  .print-list {
    padding-top: $paddingTop;
  }

  .print-contanier {
    padding-bottom: 0;
    page-break-after: always;
  }

  .print-padding {
    padding-top: $paddingTop;
  }
}
 </style>
