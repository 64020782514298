<template>
  <div>
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      class="print-contanier"
    >
      <print-header
        :customer-order-no="buildCustomerOrder(publicPrint.customerOrderNo, tableNumber)"
        :deliver-no="publicPrint.deliverOrderNo"
        :title="config.title"
        :title-name="titleName"
        :print-config="printList"
      />
      <print-info :info="fileds.PUBLIC" :form="publicPrint" />
      <print-table
        class="table"
        :headers="fileds.TABLECOLUMN"
        :list="table"
        :footers="tableFooters"
      />
      <div class="parkingList">
        <div class="row">
          <div class="title">
            备注栏：{{ publicPrint.note }}
          </div>
        </div>
        <div class="row">
          <div class="title">
            <div>发货人：</div>
            <div>出货件数：</div>
            <div>出货日期：</div>
          </div>
          <div class="title">
            <div>提货人：</div>
            <div>提货件数：</div>
            <div>配送日期：</div>
          </div>
          <div class="title">
            <div>收货人：</div>
            <div>收货日期：</div>
            <div>收货件数：</div>
          </div>
        </div>
      </div>
      <div class="page-tip">
        页/总页数：{{ tableNumber + 1 }} / {{ tables.length }}
      </div>
    </div>
    <div />
  </div>
</template>

<script>
import unitNumber from '@/utils/unitNumber';
import { getCustomerTitle } from '@/api/shipper';
import PrintHeader from './components/printHeader.vue';
import PrintInfo from '../../components/PrintInfo/PrintInfo.vue';

import PrintTable from '../../components/PrintTable/PrintTable.vue';
import { PRINT_BUTTON } from '../../components/PrintButton/config';
import fileds from './fileds';
import config from './config';

export default {
  name: 'DeliveryListNormalCustomized1',
  components: {
    PrintHeader,
    PrintInfo,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PRINT_BUTTON,
      fileds,
      config,
      publicPrint: {},
      tables: [],
      tableFooters: [
        {
          label: '汇总数量', class: 'letter-spacing-1',
        },
        {
          label: '', class: 'letter-spacing-1', colspan: 9,
        },
      ],
      totalNum: null,
      titleName: '',
    };
  },

  created() {
    this.tables = this.formatTable();
  },
  mounted() {
    getCustomerTitle({ shipperId: this.publicPrint.shipperId }).then((res) => {
      this.titleName = res || '鲜生活冷链物流有限公司';
    });
  },
  methods: {
    buildCustomerOrder(customerOrderNo, tableNumber) {
      return customerOrderNo ? customerOrderNo + tableNumber + 1 : '';
    },
    formatTable() {
      const { tables: list = [], publicPrint = {} } = this.printList;
      const tables = [];
      this.publicPrint = publicPrint;
      let tablePage = 0;
      let defaultDeliverNum = 0;
      list.forEach((val, index) => {
        defaultDeliverNum += val.deliverDefaultUnitQuantity * 1000 || 0;
        const {
          txt: bookingNumTxt = null,
        } = unitNumber.calculate({ ...val, smallUnitNumber: val.bookingNum });
        const {
          txt: deliverNumTxt = null,
        } = unitNumber.calculate({ ...val, smallUnitNumber: val.deliverNum });
        const item = {
          ...val,
          bookingNumTxt,
          deliverNumTxt,
          index: index + 1,
        };
        if (index === config.maxRowsNumber * tablePage) {
          tablePage += 1;
          tables.push([]);
        }
        tables[tablePage - 1].push(item);
      });

      this.tableFooters = [
        {
          label: '汇总数量', class: 'letter-spacing-1',
        },
        {
          label: defaultDeliverNum / 1000, class: 'letter-spacing-1', colspan: 9,
        },
      ];
      return tables;
    },

  },
};
</script>
<style lang="scss" scoped>
.print-contanier {
  page-break-after: always;
  padding-bottom: 20px;
}

.parkingList {
  margin-top: 0;
  border-top: 0;

  .row {
    display: flex;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;

    & > div {
      flex: 1;
      line-height: 24px;
      padding-left: 8px;
    }

    & > div:not(:last-of-type) {
      border-right: 1px solid #000;
    }
  }
}

.page-tip {
  margin-top: 5px;
  padding-right: 40px;
  font-size: 12px;
  text-align: right;
}

@media print {
  .print-contanier {
    padding-bottom: 0;
  }
}
 </style>
