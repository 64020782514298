export default {
  // 温层排序 冷藏>冷冻>常温>恒温
  temperatureLayerSorting: ['REFRIGERATE', 'COLD', 'NORMAL', 'CONSTANT'],
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '餐厅代码:',
      value: 'thirdShopCode',
      width: 1,
    }, {
      label: '餐厅名称:',
      value: 'stationName',
      width: 1,
    }, {
      label: '订货日期:',
      value: 'createTime',
      width: 1,
    }],
    [{
      label: '餐厅联系人:',
      value: 'stationContact',
      width: 1,
    }, {
      label: '餐厅电话:',
      value: 'stationTelephone',
      width: 1,
    }, {
      label: '餐厅手机:',
      value: 'stationTelephone',
      width: 1,
    }],
    [{
      label: '客户地址:',
      value: 'stationAddress',
      width: 2,
    }, {
      label: '发货仓紧急联系人:',
      value: 'warehouseLinkMan',
      width: 1,
    }],
    [{
      label: '发货仓库联系人:',
      value: 'warehouseLinkMan',
      width: 1,
    }, {
      label: '实发面包栏________个',
      value: '',
      width: 1,
    }, {
      label: '实退面包栏________个',
      value: '',
      width: 1,
    }],
    [{
      label: '',
      value: '',
      width: 1,
    }, {
      label: '实发蔬菜框________个',
      value: '',
      width: 1,
    }, {
      label: '实退蔬菜框________个',
      value: '',
      width: 1,
    }],
  ],
  TABLECOLUMN: [
    { label: '序号', prop: 'index' },
    {
      label: '货品代码', prop: 'shipperGoodsCode', width: '64px', maxLength: 10,
    },
    {
      label: '货品描述', prop: 'goodsName', width: '70px', maxLength: 8,
    },
    {
      label: '包装描述', prop: 'spec', width: '70px', maxLength: 5,
    },
    {
      label: '单位', prop: 'defaultUnit', width: '80px', maxLength: 5,
    },
    {
      label: '订单量', prop: 'bookingNum', width: '80px', maxLength: 8,
    },
    {
      label: '发货量', prop: 'deliverNum', width: '70px', maxLength: 5,
    },
    {
      label: '清点栏', prop: '', width: '70px', maxLength: 5,
    },
    { label: '生产日期', prop: 'productDate', width: '59px' },
    { label: '送货差异', prop: '', width: '59px' },
  ],
};
