export default {
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '客户门店名称:',
      prop: 'stationName',
      span: 16,
    }, {
      label: '客户订单号:',
      prop: 'customerOrderNo',
      span: 8,
    }],
    [{
      label: '供应商编码:',
      prop: '',
      span: 8,
    }, {
      label: '供应商名称:',
      prop: '',
      span: 8,
    }, {
      label: '日期:',
      prop: 'deliverTime',
      span: 8,
    }],
    [{
      label: '门店电话:',
      prop: 'stationTelephone',
      span: 8,
    }, {
      label: '门店地址:',
      prop: 'stationAddress',
      span: 8,
    }, {
      label: '编号:',
      prop: 'customerStationCode',
      span: 8,
    }],
  ],
  TABLECOLUMN: [
    {
      label: '商品编码', prop: 'shipperGoodsCode', class: 'letter-spacing-1', width: '12%',
    },
    {
      label: '商品条码', prop: 'internationalCode', class: 'letter-spacing-1', width: '9%',
    },
    {
      label: '产品名称', prop: 'goodsName', width: '19%', mediumLength: 7, maxLength: 9,
    },
    {
      label: '规格型号', prop: 'spec', width: '9%', maxLength: 6,
    },
    { label: '销售单位', prop: 'defaultUnit', width: '9%' },
    { label: '预约数量', prop: 'bookingNumTxt', width: '11%' },
    {
      label: '出货数', prop: 'deliverNumTxt', width: '11%', maxLength: 6,
    },
    {
      label: '箱数', prop: 'deliverConvertBigQuantity', width: '5%', class: 'letter-spacing-1 number',
    },
    { label: '收货数' },
    { label: '备注' },
  ],
};
