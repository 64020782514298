// 匀鲜-汉堡王   排序规则
export const HanBaoWangSortRule = ['冻包', '冻货', '干货', '工衣', '冷藏', '蔬菜', '鲜包', '资料', '空运蔬菜', '食品', '非食品'];
export const TEMPERATURES = {
  1: '',
  2: '',
  3: '常温',
  4: '-18℃',
  5: '0-4℃',
  6: '常温',
  7: '常温',
  8: '0-4℃',
  9: '常温',
  10: '-18℃',
};
