export default {
  PICKUP_TYPE: [{
    value: 'CUSTOMER_DELIVERY',
    label: '客户自提',
  }, {
    value: 'TMS配送',
    label: 'TMS_DELIVERY',
  }],
  DELIVER_TYPE: [
    {
      value: 'ORDER_OUT',
      label: '订单出库',
    }, {
      value: 'ALLOT_OUT',
      label: '调拨出库',
    }, {
      value: 'SHIFT_PARKING_OUT',
      label: '移库移位(出)',
    }, {
      value: 'ADJUST_OUT',
      label: '调整出库',
    }, {
      value: 'INVENTORY_LESS',
      label: '盘亏出库',
    }, {
      value: 'DESTROY_OUT',
      label: '销毁出库',
    }, {
      value: 'CANCEL_OUT',
      label: '退供出库',
    }, {
      value: 'TOTAL_PICK_DOWN',
      label: '总拣下架',
    }, {
      value: 'TEMPORARY_OUT',
      label: '零担出库',
    }, {
      value: 'OTHER_IT_OUT',
      label: '其它出库',
    }, {
      value: 'OTHER_HE_OUT',
      label: '其他出库',
    }, {
      value: 'ALLOT_OUT_BOUND_IN',
      label: '调拨出库(库内)',
    },
  ],
};
