<template>
  <div
    class="print-info"
    :style="{fontSize: fontSize}"
  >
    <div
      v-for="(row, index) in info"
      :key="index"
      class="row"
    >
      <div
        v-for="item in row"
        :key="item.prop"
        class="col"
        :style="{'flex': item.span || 1}"
      >
        <p>
          {{ item.label }}
          <span :style="item.textStyle">{{ form[item.prop] }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => ([]),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    fontSize: {
      type: String,
      default: '16px',
    },
  },
};
</script>
<style lang="scss" scoped >
.print-info {
  .el-form-item {
    margin-bottom: 0;
    color: #000;
  }

  :deep(.el-form-item__label) {
    color: #000;
  }

  .row {
    display: flex;
  }
}
</style>
