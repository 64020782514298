<template>
  <div class="print-list">
    <div>
      <print-header
        :title="config"
        :show-ext="false"
        :title-name="titleName"
        hide-logo
        hide-barcode
        :print-config="printList"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }}
              <span :style="item.textStyle">{{ publicPrint[item.value] }}</span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="isShowMerge">
        <div
          v-for="(item,index) in printList.mergeTable"
          :key="index"
          class="print-public"
        >
          <el-row class="bill-info">
            <el-col :span="8">
              销售订单号：<span class="bill-info-content">{{ item.salesOrderNo }}</span>
            </el-col>
            <el-col :span="8">
              外部订单号：<span class="bill-info-content">{{ item.ext?.originCustomerOrderNo }}</span>
            </el-col>
            <el-col :span="8">
              到货日期：<span class="bill-info-content">{{ item.bookingTime }}</span>
            </el-col>
            <el-col :span="8">
              发货订单号：<span class="bill-info-content">{{ item.customerOrderNo }}</span>
            </el-col>
            <el-col :span="24">
              <!-- 取goodsTypeCod -->
              备注：<span>{{ item.temperatures }}</span>
            </el-col>
          </el-row>
          <print-table
            class="table"
            :headers="fileds.TABLECOLUMN"
            :list="item.tableData"
          />
        </div>
      </div>
      <div v-else>
        <el-row class="bill-info">
          <el-col :span="8">
            销售订单号：<span class="bill-info-content">{{ publicPrint.salesOrderNo }}</span>
          </el-col>
          <el-col :span="8">
            外部订单号：<span class="bill-info-content">
              {{ publicPrint.ext?.originCustomerOrderNo }}
            </span>
          </el-col>
          <el-col :span="8">
            到货日期：<span class="bill-info-content">{{ publicPrint.bookingTime }}</span>
          </el-col>
          <el-col :span="8">
            发货订单号：<span class="bill-info-content">{{ publicPrint.customerOrderNo }}</span>
          </el-col>
          <el-col :span="24">
            备注：<span class="bill-info-content">{{ publicPrint.temperatures }}</span>
          </el-col>
        </el-row>
        <print-table
          class="table"
          :headers="fileds.TABLECOLUMN"
          :list="tables"
        />
      </div>
    </div>
    <div class="footer-sign">
      <el-row>
        <el-col :span="10" class="footer-sign_stamp">
          <img v-if="isShowMerge" src="@/assets/sy_hbw.png">
        </el-col>
        <el-col :span="14">
          <el-row class="tip">
            <el-col :offset="12">
              配送差异以“送货差异”栏为准
            </el-col>
          </el-row>
          <div class="sign-name">
            <el-row>
              <el-col :span="8">
                仓库签字：
              </el-col>
              <el-col :span="8">
                提货司机签字：
              </el-col>
              <el-col :span="8">
                餐厅签字：
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                日期：
              </el-col>
              <el-col :span="8">
                日期：
              </el-col>
              <el-col :span="8">
                日期：
              </el-col>
            </el-row>
          </div>
          <div class="sign-info">
            <el-row>
              <el-col :span="12">
                司机联系方式：
              </el-col>
              <el-col :span="12">
                车牌号：
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                到达门店时间：
              </el-col>
              <el-col :span="12">
                离开门店时间：
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getCustomerTitle } from '@/api/shipper';
import Moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import { PRINT_BUTTON } from '../../components/PrintButton/config';
import config from './config';
import fileds from './fileds';
import { TEMPERATURES } from './constant';

const CLASSIFY_PREFIX = '按温度分组合计：';
const CLASSIFY_HEADERS = [
  {
    label: '行号', prop: 'index', colspan: 6, class: 'no-border', width: '5%',
  },
  {
    label: '发货量', prop: 'deliverNum', class: 'no-border', width: '8%',
  },
  { label: '清点栏', class: 'no-border' },
  { label: '生产日期', width: '8%', class: 'no-border' },
  { label: '送货差异' },
];
const emergencyContact = '张治华 18842419293';
export default {
  name: 'DeliveryListNormalNor',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
    mergeStores: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PRINT_BUTTON,
      config,
      fileds,
      TEMPERATURES,
      publicPrint: {},
      tables: [],
      titleName: '',
    };
  },
  computed: {
    isShowMerge() {
      return this.mergeStores;
    },

  },
  created() {
    this.formatTable();
  },
  mounted() {
    getCustomerTitle({ shipperId: this.publicPrint.shipperId }).then((res) => {
      this.titleName = res || '鲜生活冷链物流有限公司';
    });
  },
  methods: {
    formatTable() {
      const { tables: list = [], publicPrint = {} } = this.classifyByTemperature(this.printList);
      this.publicPrint = {
        ...publicPrint,
        emergencyContact,
      };
      this.tables = list;
    },
    // 根据温层分类货品
    classifyByTemperature(data) {
      const list = [];
      let lineNumber = 1; // 行号
      let totalPickNum = 0;
      const temperatures = new Set();
      data.tables.forEach((current, index) => {
        totalPickNum += current.deliverNum * 1000;
        list.push({
          ...current,
          productDate: Moment.format(current.productDate, YMD),
          index: lineNumber,
        });
        const next = data.tables[index + 1];
        if (data.tables.length - 1 === index) {
          // 如果是最后一行，添加温层栏
          this.addClassifyLabel(list, current, totalPickNum);
          if (current.goodsTypeCode) {
            temperatures.add(TEMPERATURES[current.goodsTypeCode]);
          }
          return;
        }
        if (current.goodsTypeCode === next.goodsTypeCode) {
          lineNumber += 1;
        } else {
          this.addClassifyLabel(list, current, totalPickNum);
          if (current.goodsTypeCode) {
            temperatures.add(TEMPERATURES[current.goodsTypeCode]);
          }
          lineNumber = 1;
          totalPickNum = 0;
        }
      });
      return {
        tables: list,
        publicPrint: {
          temperatures: [...temperatures].join(' + '),
          ...data.publicPrint,
        },
      };
    },
    addClassifyLabel(list, info, deliverNum) {
      const { goodsTypeCode } = info;
      const classifiedInfo = `${CLASSIFY_PREFIX}${TEMPERATURES[goodsTypeCode] || ''}`;
      list.push({
        ...info,
        index: classifiedInfo,
        headers: CLASSIFY_HEADERS,
        deliverNum: deliverNum / 1000,
      });
    },

  },
};
</script>
<style lang="scss" scoped>
.print-list {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }

    .increase-size {
      font-size: 20px;
    }
  }
}

.bill-info-content {
  font-weight: 600;
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    height: 28px;
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  margin-top: 15px;

  .footer-sign_stamp {
    img {
      margin-left: 100px;
      width: 180px;
    }
  }

  .tip {
    font-weight: bold;
    font-size: 16px;
  }

  .sign-name {
    margin-top: 5px;
    font-weight: bold;
    line-height: 20px;
  }

  .sign-info {
    line-height: 20px;
    margin-top: 10px;
  }
}

@media print {
  // 送货单顶部边距需要增加。因为实际打印时需要再设置边距，然后头部有部分信息展示不全。
  $paddingTop: 10px;

  .print-list {
    padding-top: $paddingTop;
  }

  .print-contanier {
    padding-bottom: 0;
    page-break-after: always;
  }

  .print-padding {
    padding-top: $paddingTop;
  }
}
 </style>
