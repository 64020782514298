export default {
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '日期:',
      value: 'deliverTime',
      width: 1,
    }, {
      label: '客户:',
      value: 'stationName',
      width: 2,
    }, {
      label: '订单类型:',
      value: 'deliverType',
      width: 1,
    }],
    [{
      label: '单号:',
      value: 'customerOrderNo',
      width: 1,
    }, {
      label: '地址:',
      value: 'stationAddress',
      width: 2,
    }, {
      label: '订单有效期：',
      width: 1,
    }],
    [{
      label: '工厂:',
      value: 'shipperName',
      width: 3,
    }, {
      label: '收货电话:',
      value: 'stationTelephone',
      width: 2,
    }, {
      label: '业务电话:',
      width: 2,
    },
    {
      label: '采购订单:',
      width: 3,
    }, {
      label: '送货方式:',
      width: 2,
    }],
  ],
  TABLECOLUMN: [
    {
      label: '国际条码', prop: 'internationalCode', width: '10%', class: 'letter-spacing-1',
    },
    {
      label: '物料', prop: 'shipperGoodsCode', width: '8%', class: 'letter-spacing-1',
    },
    {
      label: '物料描述', prop: 'goodsName', width: '30%', mediumLength: 12,
    },
    { label: '位置', width: '6%' },
    {
      label: '箱数', prop: 'bookingConvertBigQuantity', width: '5%', mediumLength: 4, maxLength: 8, showZero: true,
    },
    {
      label: '数量', prop: 'bookingDefaultUnitQuantity', width: '5%', mediumLength: 4, maxLength: 8, showZero: true,
    },
    { label: '单位', prop: 'defaultUnit', width: '4%' },
    { // 客户批次为批属性字段
      label: '客户批次', prop: 'customer_batch', width: '8%', class: 'letter-spacing-1',
    },
    { label: '到期日', width: '7%', prop: 'vfdat' },
    {
      label: '含税单价',
      prop: 'unitPrice',
      width: '5%',
      showZero: true,
      class: 'letter-spacing-1',
    },
    {
      label: '含税额', prop: 'totalTaxPrice', width: '6%', showZero: true, class: 'letter-spacing-1',
    },
    {
      label: '未税额', prop: 'totalPriceWithoutTax', width: '6%', showZero: true, class: 'letter-spacing-1',
    },
  ],
  // 签名
  FOOTER_SIGN: [
    [{
      label: '录单：',
      width: 5,
    }, {
      label: '仓库签字:',
      width: 5,
    }, {
      label: '司机签字：',
      width: 5,
    }, {
      label: '车牌号：',
      width: 5,
    },
    {
      label: '客户签收：',
      width: 4,
    }],
  ],
};
