export default {
  // 温层排序 冷藏>冷冻>常温>恒温
  temperatureLayerSorting: ['REFRIGERATE', 'COLD', 'NORMAL', 'CONSTANT'],
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '所属货主:',
      value: 'shipperName',
      width: 1,
    }, {
      label: '客户订单号:',
      value: 'customerOrderNo',
      width: 1,
    }, {
      label: '出库单号:',
      value: 'deliverOrderNo',
      width: 1,
    }],
    [{
      label: '门店名称:',
      value: 'stationName',
      width: 1,
    }, {
      label: '门店编码:',
      value: 'customerStationCode',
      width: 1,
    }, {
      label: '线路名称:',
      value: 'lineName',
      width: 1,
      textStyle: {
        fontWeight: 400,
      },
    }],
    [{
      label: '送货日期:',
      value: 'bookingTime',
      width: 1,
    }, {
      label: '联系人:',
      value: 'stationContact',
      width: 1,
      textStyle: {
        fontWeight: 400,
      },
    }, {
      label: '备注:',
      value: 'remark',
      width: 1,
      textStyle: {
        fontWeight: 400,
      },
    }],
    [{
      label: '地址:',
      value: 'stationAddress',
      textStyle: {
        fontWeight: 400,
      },
    }],
  ],
  // 签名
  FOOTER_SIGN: [
    [{
      label: '冷藏',
      width: 1,
      align: 'center',
    }, {
      value: '',
      width: 2,
    }, {
      label: '冷冻',
      width: 1,
      align: 'center',
    }, {
      value: '',
      width: 2,
    }, {
      label: '常温',
      width: 1,
      align: 'center',
    }, {
      value: '',
      width: 2,
    }, {
      label: '恒温',
      width: 1,
      align: 'center',
    }, {
      value: '',
      width: 2,
    }],
    [{
      label: '周转物类型:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '到达门店数:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '门店返回数:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }],
    [{
      label: '仓库(签字或盖章):',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '日期:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '门店签字:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }],
    [{
      label: '司机签字:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '车牌号:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '司机电话:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }],
    [{
      label: '制单人:',
      width: 3,
    }, {
      value: 'createUserName',
      width: 2,
    }, {
      label: '车辆卫生:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '开始装车时间:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }],
    [{
      label: '单证交接:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '车辆温度:',
      width: 3,
    }, {
      value: '',
      width: 2,
    }, {
      label: '结束装车时间:',
      width: 3,
    }, {
      label: '',
      width: 2,
    }],
    [{
      label: '车辆承诺：1.货物明细已清点无误。2.运输过程一定严格控制温度。3.保持车辆清洁。',
      width: 1,
    }],
    [{
      label: '第一联：单证联',
      width: 1,
      align: 'center',
    }, {
      label: '第二联：财务联',
      width: 1,
      align: 'center',
    }, {
      label: '第三联：仓库联',
      width: 1,
      align: 'center',
    }, {
      label: '第四联：客户联',
      width: 1,
      align: 'center',
    }],
  ],
};

export const getTableHeaders = ({
  batchAttributes = [],
  feilds = {},
}) => {
  const dynamicList = batchAttributes.map((item) => ({
    label: item.label, width: '100px', prop: item.value, class: 'letter-spacing-1',
  }));

  return [
    { label: '序号', prop: 'index', width: '30px' },
    {
      label: '货主货品编码', prop: 'shipperGoodsCode', width: '110px', class: 'letter-spacing-1',
    },
    {
      label: '货品名称', prop: 'goodsName', mediumLength: 12,
    },
    {
      label: '规格', prop: 'spec', width: '80px',
    },
    { label: '温层', prop: 'temperatureLayerTxt', width: '36px' },
    { label: '保质期', prop: 'shelfLife', width: '40px' },
    ...dynamicList,
    {
      label: '默认单位', prop: 'defaultUnit', width: '36px',
    },
    {
      label: '默认数量', prop: feilds.defaultNum, width: '40px', class: 'letter-spacing-1 default-num-size',
    },
    {
      label: '实发数量', prop: 'bookingNumTxt', width: '60px', mediumLength: 4, maxLength: 8,
    },
    { label: '签收数量', width: '108px' },
  ];
};
