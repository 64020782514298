<template>
  <div>
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      class="print-contanier"
    >
      <print-header
        :customer-order-no="buildCustomerOrder(publicPrint.customerOrderNo, tableNumber)"
        :deliver-no="publicPrint.deliverOrderNo"
        :title="config.title"
        :title-name="titleName"
        :print-config="printList"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }}
              <span class="font-size">{{ `${publicPrint[item.value] || ''}` }}</span>
            </p>
          </div>
        </div>
      </div>
      <print-table
        class="table table-font-size"
        :headers="fileds.TABLECOLUMN"
        :list="table"
        :footers="tableFooters"
      />
      <div class="footer-sign">
        <div
          v-for="(row, index) in fileds.FOOTER_SIGN"
          :key="index"
          class="row"
        >
          <div
            v-for="(item, i) in row"
            :key="i"
            class="col"
            :style="{'flex': item.width}"
          >
            <p v-if="item.label" :style="{textAlign: item.align}">
              {{ item.label }}
            </p>
            <p v-else>
              {{ item.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomerTitle } from '@/api/shipper';
import moment from 'moment';
import PrintHeader from './components/printHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import { PRINT_BUTTON } from '../../components/PrintButton/config';
import fileds from './fileds';
import config from './config';

export default {
  name: 'DeliveryListNormalCustomized1',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PRINT_BUTTON,
      fileds,
      config,
      publicPrint: {},
      tables: [],
      tableFooters: [],
      footerData: {},
      titleName: '',
    };
  },

  created() {
    this.tables = this.formatTable();
    this.tableFooters = [
      {
        label: '合计：', prop: '', width: '10%', class: 'letter-spacing-1',
      },
      {
        label: `<div class="child-tf">
        <div>材积：${this.footerData.volum || ''}</div>
        <div>毛重：${this.footerData.btgew || ''}</div>
      </div>`,
        width: '44%',
        class: 'letter-spacing-1',
        colspan: 3,
      },
      {
        label: `${this.footerData.bigBookingNum}`, width: '5%', mediumLength: 4, maxLength: 8, showZero: true,
      },
      {
        label: `${this.footerData.defaultBookingNum}`, width: '5%', mediumLength: 4, maxLength: 8, showZero: true,
      },
      { label: '', prop: 'defaultUnit', width: '4%' },
      { label: '', prop: 'batchNo', width: '8%' },
      { label: '', prop: 'vfdat', width: '7%' },
      { label: '', prop: 'unitPrice', width: '5%' },
      { label: `${this.footerData.totalTaxPrice || ''}`, width: '6%' },
      { label: `${this.footerData.totalPriceWithoutTax || ''}`, width: '6%' },
    ];
  },
  mounted() {
    getCustomerTitle({ shipperId: this.publicPrint.shipperId }).then((res) => {
      this.titleName = res || '鲜生活冷链物流有限公司';
    });
  },
  methods: {
    buildCustomerOrder(customerOrderNo, tableNumber) {
      if (!customerOrderNo) {
        return '';
      }
      let num = `0${tableNumber + 1}`;
      const { length } = num;
      num = num.substring(length - 2, length);
      return customerOrderNo + num;
    },
    // 优先处理 ext数据
    xfjExt() {
      const { tables: list = [], publicPrint = {} } = this.printList;
      // let stationName = '';
      let { ext } = publicPrint || {};
      if (typeof ext === 'string') {
        ext = JSON.parse(ext);
      }
      let { xfjExt = {} } = ext || {};
      if (typeof xfjExt === 'string') {
        xfjExt = JSON.parse(xfjExt);
        // stationName = (xfjExt.kunag || '')
        //   + (xfjExt.sellStation ? xfjExt.sellStation.replace(/null/gi, '') : '');
      }
      const { goodsList, ...chaildForm } = xfjExt || {};
      let tables = [];
      if (Array.isArray(goodsList)) {
        tables = goodsList.map((item) => ({
          ...item,
          internationalCode: item.ean11,
          shipperGoodsCode: item.matnr,
          goodsName: item.maktx,
          bookingConvertBigQuantity: item.zcase,
          bookingDefaultUnitQuantity: item.lfimg,
          defaultUnit: item.vrkme,
          batchNo: item.charg,
          unitPrice: item.znetpr,
          totalTaxPrice: item.zptbp,
          totalPriceWithoutTax: item.netbp,
        }));
      } else {
        tables = list.map((item) => ({
          ...item,
          internationalCode: item.ext?.internationalCode,
          unitPrice: item.ext?.unitPriceWithTax,
          totalTaxPrice: item.ext?.priceWithTax,
          totalPriceWithoutTax: item.ext?.priceWithoutTax,
        }));
      }
      return {
        publicPrint: {
          ...publicPrint,
          ...chaildForm,
          // stationName: stationName || publicPrint.stationName,
        },
        tables,
      };
    },
    formatTable() {
      const { tables: list = [], publicPrint = {} } = this.xfjExt();
      const tables = [];
      this.publicPrint = publicPrint;
      let tablePage = 0;
      let bigBookingNum = 0;
      let defaultBookingNum = 0;
      let volum = 0;
      let btgew = 0;
      let totalTaxPrice = 0;
      let totalPriceWithoutTax = 0;
      const tableRowsCount = config.maxRowsNumber;
      list.forEach((val, index) => {
        const attributes = val.batchManage?.attributes || {};
        volum += val.bookingVolume * 1000;
        btgew += val.bookingWeight * 1000;
        bigBookingNum += val.bookingConvertBigQuantity * 1000;
        defaultBookingNum += val.bookingDefaultUnitQuantity * 1000;
        totalTaxPrice += val.totalTaxPrice * 1000;
        totalPriceWithoutTax += val.totalPriceWithoutTax * 1000;
        const item = {
          ...val,
          ...attributes,
          index: index + 1,
          vfdat: (val.productDate && val.shelfLife) ? moment(val.productDate).add(val.shelfLife, 'days').format('YYYYMMDD') : '',
        };

        if (index === tableRowsCount * tablePage) {
          tablePage += 1;
          tables.push([]);
        }
        tables[tablePage - 1].push(item);
      });

      this.footerData = {
        volum: publicPrint.volum || volum / 1000,
        btgew: publicPrint.btgew || btgew / 1000,
        bigBookingNum: bigBookingNum / 1000,
        defaultBookingNum: defaultBookingNum / 1000,
        totalTaxPrice: totalTaxPrice / 1000,
        totalPriceWithoutTax: totalPriceWithoutTax / 1000,
      };
      return tables;
    },
  },
};
</script>
<style lang="scss" scoped>
.print-contanier {
  page-break-after: always;
  padding-bottom: 20px;
}

.row {
  display: flex;

  .col {
    span {
      // font-weight: 600;
    }
  }
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  p {
    padding: 0 10px;
  }
}

.font-size {
  font-size: 12px;
}

.table-font-size {
  font-size: 12px;
}

@media print {
  .print-contanier {
    padding-bottom: 0;
  }
}
</style>
<style lang="scss">
.child-tf {
  display: flex;

  div {
    flex: 1;
  }
}
</style>
