<template>
  <div class="print-page-header">
    <img
      class="logo"
      src="@/assets/xufuji.png"
    >
    <div
      v-if="$customerOrderNo.code"
      class="customerOrderNo"
    >
      <nh-barcode
        :value="$customerOrderNo.code"
        :text="$customerOrderNo.text"
        size="big"
      />
    </div>
    <div class="title">
      <h3>
        {{ $title.company }}
        <span v-if="$title.oneLine && $title.sheetName">-{{ $title.sheetName }}</span>
      </h3>
      <h3 v-if="!$title.oneLine && $title.sheetName">
        {{ $title.sheetName }}
      </h3>
    </div>
    <div :class="['ptint-info', $deliverNo&&'ptint-barcode']">
      <nh-barcode
        v-if="$deliverNo"
        :value="$deliverNo.code"
        :text="$deliverNo.text"
        size="big"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeliveryListNormalCustomizedHeader',
  props: {
    title: {
      type: Object,
      default() {
        return {};
      },
    },
    customerOrderNo: {
      default: '',
      type: [String, Object],
    },
    deliverNo: {
      default: '',
      type: [String, Object],
    },
  },
  data() {
    return {
      type: '',
      tableInfos: {
        lineNo: '',
      },
      printCount: 0,
    };
  },
  computed: {
    $title() {
      return {
        ...this.title,
      };
    },
    $customerOrderNo() {
      // 外部传入条码号，使用外部传入的条码
      if (typeof this.customerOrderNo === 'string') {
        // 如果已有条码，使用条码
        return {
          code: this.customerOrderNo,
          text: '',
        };
      }
      return { ...this.customerOrderNo };
    },
    $deliverNo() {
      // 外部传入条码号，使用外部传入的条码
      if (typeof this.deliverNo === 'string') {
        // 如果已有条码，使用条码
        return {
          code: this.deliverNo,
          text: '',
        };
      }
      return { ...this.deliverNo };
    },
  },
};
</script>

 <style lang="scss" scoped>
.print-page-header {
  * {
    margin: 0;
    padding: 0;
  }

  position: relative;
  min-height: 56px;

  .logo {
    height: 50px;
    width: auto;
    position: absolute;
    left: 30px;
    top: 0;
  }

  .customerOrderNo {
    font-size: 12px;
    line-height: 26px;
    position: absolute;
    left: 110px;
    top: 3px;
  }

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;

    h3 {
      font-size: 20px;
    }
  }

  .ptint-info {
    font-size: 12px;
    line-height: 26px;
    position: absolute;
    right: 50px;
    top: 3px;
  }
}
</style>
